/* Fetch fonts */
@import url("https://fonts.googleapis.com/css?family=Calistoga|Raleway:400,500&display=swap");

/* Set base rem */
html {
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

h1,
h2,
p {
  line-height: 1.5;
}

h2,
p,
input {
  font-family: "Raleway", sans-serif;
}

input {
  color: #1a191e;
}

input::placeholder {
  color: #afabba;
}

h1 {
  font-family: "Calistoga", cursive;
  font-size: 5rem;
}

h2 {
  font-weight: 500;
  font-size: 4.2rem;
}

p {
  font-size: 2.8rem;
  font-weight: 400;
}

h1,
h2,
p {
  color: #1a191e;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
